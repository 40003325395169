import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import { fontStyles } from '../constants/styles';
import { getSingletonForLang } from '../helpers/i18n';

import GradientWrapper from '../components/GradientWrapper';
import Layout from '../components/Layout';
import LocalisedLink from '../components/LocalisedLink';
import PageTitle from '../components/PageTitle';
import ExternalLink from '../components/ExternalLink';


const Paragraph = styled.p`
  margin-bottom: 1em;
  text-align: center;
  ${fontStyles.body}
`;

const NotFoundPage = ({ data, location, intl }) => {
  const navigationData = getSingletonForLang(data.allDatoCmsNavigation, intl.locale);
  return (
    <Layout location={location}>
      <GradientWrapper>
        <PageTitle><FormattedMessage defaultMessage="404 Error" id="global.notFound" /></PageTitle>
        <PageTitle><FormattedMessage defaultMessage="We can’t find the page you are looking for" id="global.cantFindPage" /></PageTitle>
        <Paragraph>
          <FormattedMessage defaultMessage="Here are a few links that may be helpful:" id="global.helpfulLinks" />
        </Paragraph>
        <Paragraph>
          <LocalisedLink to={`/${navigationData.roomsPage.slug}`}><FormattedMessage defaultMessage="Book a room" id="booking.bookingButton" /></LocalisedLink>
        </Paragraph>
        <Paragraph>
          <LocalisedLink to={`/${navigationData.restaurantPage.slug}`}><FormattedMessage defaultMessage="Our Restaurant" id="global.restaurantLink" /></LocalisedLink>
        </Paragraph>
        <Paragraph>
          <ExternalLink href={navigationData.whatsOn.url}><FormattedMessage defaultMessage="Learn What’s On" id="global.whatsOnLink" /></ExternalLink>
        </Paragraph>
      </GradientWrapper>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  intl: intlShape,
};

export const query = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        defaultLangKey
      }
    }
    allDatoCmsNavigation {
      edges {
        node {
          locale
          roomsPage {
            slug
          }
          restaurantPage {
            slug
          }
          whatsOn {
            url
          }
        }
      }
    }
  }
`;

export default injectIntl(NotFoundPage);
